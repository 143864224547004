import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import imgLogo from '../../assets/img/logo_ingram.png'

const Services = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClickContact = (e) => {
        e.preventDefault();
        navigate('/contact');
      }

    return (
        <>
            <div class="container-grey">
                <div class="prices-block">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-5 prices-anchor">
                                <a href="javascript:void();" onClick={(e) => handleClickContact(e)}>
                                    <div class="box-info">
                                        <div class="box-info-title">
                                            <h4 class="text-center green-easy">{t("solutions.services.card1.header")}</h4>
                                        </div>
                                        <div class="separar"></div>
                                        <div class="box-info-description text-center">
                                            <p>{t("solutions.services.card1.description")}</p>
                                        </div>
                                        <div class="box-info-price">
                                            <p class="price text-center"><strong>{t("shared.from")}</strong> <sup class="usd">$</sup><strong>364</strong><sup class="usd">USD</sup></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-5 prices-anchor">
                                <a href="javascript:void();" onClick={(e) => handleClickContact(e)}>
                                    <div class="box-info">
                                        <div class="box-info-title">
                                            <h4 class="text-center green-easy">{t("solutions.services.card2.header")}</h4>
                                        </div>
                                        <div class="separar"></div>
                                        <div class="box-info-description text-center">
                                            <p>{t("solutions.services.card2.description")}</p>
                                            <p>{t("solutions.services.card2.description1")}</p>
                                        </div>
                                        <div class="box-info-price">
                                            <p class="price text-center demo-phrase"><strong>{t("index.banner.button")}</strong></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="separar"></div>
                        <div class="container">
                            <div class="row row justify-content-center">
                                <div class="col-md-5 prices-anchor">
                                    <a href="javascript:void();" onClick={(e) => handleClickContact(e)}>
                                        <div class="box-info">
                                            <div class="box-info-title">
                                                <h4 class="text-center green-easy">{t("solutions.services.card3.header")}</h4>
                                                {/* <h4 class="text-center green-easy">POD</h4> */}
                                            </div>
                                            <div class="separar"></div>
                                            <div class="box-info-description text-center">
                                                <p>{t("solutions.services.card3.description")}</p>
                                                {/* <p>{t("solutions.services.card3.description1")}</p> */}
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <img className="logo_ingram" src={imgLogo} alt="logo-ingram" />
                                            </div>
                                            
                                            <div class="box-info-price">
                                            <p class="price text-center demo-phrase"><strong>{t("index.banner.button")}</strong></p>
                                                {/* <p class="price text-center"><strong>{t("shared.from")}</strong> <sup class="usd">$</sup><strong>40</strong><sup class="usd">USD</sup></p> */}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-md-5 prices-anchor">
                                    <a href="javascript:void();" onClick={(e) => handleClickContact(e)}>
                                        <div class="box-info">
                                            <div class="box-info-title">
                                                <h4 class="text-center green-easy">Biblio.digital</h4>
                                            </div>
                                            <div class="separar"></div>
                                            <div class="box-info-description text-center">
                                                <p>{t("solutions.services.card4.description")}</p>
                                                <p>{t("solutions.services.card4.description1")}</p>
                                            </div>
                                            <div class="box-info-price">
                                                <p class="price text-center"><strong>{t("shared.from")}</strong> <sup class="usd">$</sup><strong>18</strong><sup class="usd">USD</sup><br /><small>{t("shared.monthly")}</small></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="separar"></div>
                        <div class="separar"></div>
                        <div class="container ver-mas d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <h3 style={{"color": "#7FBB23"}}>{t("solutions.features.title")}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="separar"></div>
                        <div class="separar"></div>
                        <div class="separar"></div>
                        <div class="container d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            <div class="row">
                                <div class="col-md-12">
                                    <div id="price-table" class="tabla-precios">
                                    <table class="table table-responsive">
                                        <tr>
                                            <td class="no-border"></td>
                                            <td class="text-center">
                                                <h3 class="green-easy"><strong>Basic</strong></h3>
                                                <strong>{t("shared.from")}</strong><br />
                                                <h3><sup>$</sup><strong>364</strong><sup>USD</sup></h3>
                                            </td>
                                            <td class="text-center">
                                                <h3 class="green-easy"><strong>Publisher</strong></h3>
                                                <h4><strong><a href="javascript:void();" onClick={(e) => handleClickContact(e)}>{t("shared.contactus")}</a></strong></h4>
                                            </td>
                                            <td class="text-center">
                                                <h3 class="green-easy"><strong>{t("solutions.features.table.text15")}</strong></h3>
                                                <img src={imgLogo} className="logo_ingram" alt="logo-ingram" />
                                                <h4><strong><a href="javascript:void();" onClick={(e) => handleClickContact(e)}>{t("shared.contactus")}</a></strong></h4>
                                                {/* <strong>{t("shared.from")}</strong><br />
                                                <h3><sup>$</sup><strong>40</strong><sup>USD</sup></h3> */}
                                            </td>
                                            <td class="text-center">
                                                <h3 class="green-easy"><strong>Biblio.digital</strong></h3>
                                                <strong>{t("shared.from")}</strong><br />
                                                <h3><sup>$</sup><strong>18</strong><sup>USD</sup></h3>
                                                <p><small>{t("shared.monthly")}</small></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="header">{t("solutions.features.table.text")}</td>
                                        </tr>
                                        <tbody>
                                            <tr>
                                                <td>{t("solutions.features.table.text1")}</td>
                                                <td>- {t("shared.of")} 10<br />{t("shared.publications")}</td>
                                                <td>+ {t("shared.of")} 10<br /> {t("shared.publications")}</td>
                                                {/* <td>{t("shared.from")} 1 {t("shared.book")}</td> */}
                                                <td>{t("solutions.features.table.text16")}</td>
                                                <td>{t("shared.from")} 60<br /> {t("shared.publications")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("shared.distribution")}<br />(ebook /{t("shared.audiobook")}/{t("solutions.features.table.text2")})</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                                <td>{t("solutions.features.table.text2")}</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                            </tr>
                                           {/*  <tr>
                                                <td>{t("shared.distribution")}<br />(ebook /{t("shared.audiobook")})</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                                <td>-</td>
                                                <td>ebook / {t("shared.audiobook")}</td>
                                            </tr> */}
                                            <tr>
                                                <td style={{"text-transform": "capitalize"}}>{t("solutions.features.table.text3")}</td>
                                                <td>
                                                {t("shared.in")} 50 {t("solutions.features.table.text3")}
                                                </td>
                                                <td>
                                                {t("shared.in")} 60 {t("solutions.features.table.text3")} 
                                                </td>
                                                <td>{t("solutions.features.table.text16")}</td>
                                                <td>{t("solutions.features.table.text5")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text6")}</td>
                                                <td>-</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("solutions.features.table.text7")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text8")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text9")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text10")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text11")}</td>
                                                <td>-</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text12")}</td>
                                                <td>-</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text13")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("solutions.features.table.text14")}</td>
                                                <td>-</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                                <td>{t("shared.yes")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Services;