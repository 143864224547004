import React from 'react'
import Testimonial from './Testimonial'
import Logos from './Logos'
import { useTranslation } from 'react-i18next';

export  function Testimonials() {

    const { t } = useTranslation();

  return (
      <>
        <div className="testimonials_new">
            <div id="carouselTestimonialsIndicators" class="carousel slide  d-sm-block d-md-block d-lg-block d-xl-block" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselTestimonialsIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselTestimonialsIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselTestimonialsIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial1")}
                                                client="Paulo Santos"
                                                account="Clube de Autores"
                                                classBackground="testimonio"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial2")}
                                                client="Gabriela Said"
                                                account="El Colegio de México"
                                                classBackground="testimonio2"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial3")}
                                                client="Carlos Wynter"
                                                account="FUGA Editorial"
                                                classBackground="testimonio"   />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <Testimonial text= {'<p>' + t("testimonials.testimonial4") + '</p><p>' + t("testimonials.testimonial4_1") + '</p><p>' + t("testimonials.testimonial4_2") + '</p>'}
                                                client="Adrián Gutiérrez Ávila"
                                                account="Escritor y conferencista"
                                                classBackground="testimonio"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial5")}
                                                client="Miguel Ángel LG"
                                                account="Gil Editores"
                                                classBackground="testimonio2"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial6")}
                                                client="Jaime Miguel Moreno Garavilla"
                                                account="Facultad de Derecho (UNAM)"
                                                classBackground="testimonio"   />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial7")}
                                                client="Karla Puga"
                                                account="Mi Club de Cuentos"
                                                classBackground="testimonio"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial8")}
                                                client="Lorena Gutiérrez"
                                                account="Intersistemas SA de CV"
                                                classBackground="testimonio2"   />
                                </div>
                                <div class="col-md-4">
                                    <Testimonial text= {t("testimonials.testimonial9")}
                                                client="Guadalupe Ortiz"
                                                account="Grupo Editor Orfila Valentini"
                                                classBackground="testimonio"   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselTestimonialsIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselTestimonialsIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div className='separar'></div>
            <div className='separar'></div>
            <div className='separar'></div>
            <Logos />
        </div>
        
      </>
      
    )
}

export default Testimonials